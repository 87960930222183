export function getRedirect(path: string, anchor: string) {
  let redirectMap = new Map([
      ["/docs/api-reference.md", "/0f8fac59d1995-api-reference"],
      ["/docs/overview.md", "/ZG9jOjcxMjYx-overview"],
      //GUIDES
      ["/docs/Guides/Actions and Webhooks/actions.md", "/ZG9jOjg3NzY2NQ-actions"],
      [
          "/docs/Guides/Actions and Webhooks/webhooks.md",
          "/ZG9jOjg3NzY2Ng-webhooks",
      ],
      [
          "/docs/Guides/Common Tasks/building-surveys.md",
          "/ZG9jOjg3NzY2Nw-building-surveys",
      ],
      [
          "/docs/Guides/Common Tasks/generate-custom-distribution-links.md",
          "/ZG9jOjg3NzY2OA-generate-custom-distribution-links",
      ],
      [
          "/docs/Guides/Common Tasks/getting-information-about-distributions.md",
          "/ZG9jOjg3NzY2OQ-getting-information-about-distributions",
      ],
      [
          "/docs/Guides/Common Tasks/getting-survey-responses-via-the-new-export-apis.md",
          "/ZG9jOjg3NzY3MA-getting-survey-responses-via-the-new-export-ap-is",
      ],
      [
          "/docs/Guides/Common Tasks/listen-to-and-retrieve-responses-in-real-time.md",
          "/ZG9jOjg3NzY3MQ-retrieve-survey-responses-in-real-time",
      ],
      [
          "/docs/Guides/Common Tasks/managing-right-to-erasure.md",
          "/ZG9jOjg3NzY3Mg-managing-right-to-erasure",
      ],
      [
          "/docs/Guides/Common Tasks/managing-surveys.md",
          "/ZG9jOjg3NzY3Mw-managing-surveys",
      ],
      [
          "/docs/Guides/Common Tasks/managing-user-groups.md",
          "/ZG9jOjg3NzY3NA-managing-user-groups",
      ],
      [
          "/docs/Guides/Common Tasks/managing-users.md",
          "/ZG9jOjg3NzY3NQ-managing-users",
      ],
      [
          "/docs/Guides/Common Tasks/send-survey-as-sms-message-or-email.md",
          "/ZG9jOjg3NzY3Ng-send-survey-as-sms-message-or-email",
      ],
      [
          "/docs/Guides/Common Tasks/survey-export-new-guide.md",
          "/ZG9jOjg3NzY3Nw-new-survey-response-export-guide",
      ],
      [
          "/docs/Guides/Common Tasks/survey-response-import-guide.md",
          "/ZG9jOjg3NzY3OA-survey-response-import-guide",
      ],
      [
          "/docs/Guides/Common Tasks/working-with-survey-sessions.md",
          "/ZG9jOjg3NzY3OQ-working-with-survey-sessions",
      ],
      [
          "/docs/Guides/Common Tasks/working-with-surveys-using-the-conversational-apis.md",
          "/ZG9jOjg3NzY4MA-working-with-surveys-using-the-conversational-ap-is",
      ],
      ["/docs/Guides/overview.md", "/ZG9jOjg3NjYzMQ-overview"],
      [
          "/docs/Guides/Survey API Overview/add-survey-translations-walkthrough.md",
          "/ZG9jOjg3NzY4MQ-add-survey-translations-walkthrough",
      ],
      [
          "/docs/Guides/Survey API Overview/survey-api-introduction.md",
          "/ZG9jOjg3NzY4Mg-survey-api-introduction",
      ],
      [
          "/docs/Guides/Survey API Overview/survey-definition-use-case.md",
          "/ZG9jOjg3NzY4Mw-example-use-cases-walkthrough",
      ],
      [
          "/docs/Guides/XM Directory/importing-mailing-lists-with-transactions.md",
          "/ZG9jOjg3NzY4NA-importing-mailing-lists-with-transactions",
      ],
      [
          "/docs/Guides/XM Directory/managing-contacts-in-mailing-lists-for-xm-directory.md",
          "/ZG9jOjEyMTI4Njk0-managing-contacts-in-mailing-lists-for-xm-directory",
      ],
      [
          "/docs/Guides/XM Directory/managing-directory-contacts-for-xm-directory.md",
          "/ZG9jOjEyMTMwMzk4-managing-directory-contacts-for-xm-directory",
      ],
      [
          "/docs/Guides/XM Directory/managing-mailing-lists-for-xm-directory.md",
          "/ZG9jOjEyMTI4Njk2-managing-mailing-lists-for-xm-directory",
      ],
      [
          "/docs/Guides/XM Directory/research-core-to-xm-directory-migration-guide.md",
          "/ZG9jOjEyMTI4Njk3-research-core-to-xm-directory-migration-guide",
      ],
      [
          "/docs/Guides/XM Directory/use-case-import-data-and-do-distribution.md",
          "/ZG9jOjg3NzY4OQ-how-to-load-contact-transactions-and-distribute-survey",
      ],
      [
          "/docs/Guides/XM Directory/using-contact-transactions-in-xm-directory.md",
          "/ZG9jOjEyMTI4Njk5-using-contact-transactions-in-xm-directory",
      ],
      [
          "/docs/Guides/XM Directory/using-transaction-batches-in-xm-directory.md",
          "/ZG9jOjEyMTI4NzAw-using-transaction-batches-in-xm-directory",
      ],
      [
          "/docs/Guides/XM Directory/using-transactions-in-xm-directory.md",
          "/ZG9jOjEyMTI4NzAx-using-transactions-in-xm-directory",
      ],
      //INSTRUCTIONS
      ["/docs/Instructions/overview.md", "/8shbk1msh6f05-overview"],
      [
          "/docs/Instructions/quick-start/getting-more-secure-with-oauth.md",
          "/ZG9jOjg3NzY5Mw-security-and-o-auth",
      ],
      [
          "/docs/Instructions/quick-start/qualtrics-api-quick-start.md",
          "/24d63382c3a88-api-quick-start",
      ],
      [
          "/docs/Instructions/quick-start/things-to-know-about-the-api.md",
          "/b626f29209735-things-to-know-about-the-api",
      ],
      [
          "/docs/Instructions/Reference/base-url-and-datacenter-ids.md",
          "/1aea264443797-base-url-and-datacenter-i-ds",
      ],
      [
          "/docs/Instructions/base-url-and-datacenter-ids.md",
          "/1aea264443797-base-url-and-datacenter-i-ds",
      ],
      [
          "/base-url-and-datacenter-ids.md",
          "/1aea264443797-base-url-and-datacenter-i-ds",
      ],
      [
          "/docs/Instructions/Reference/finding-your-qualtrics-ids.md",
          "/4637b3c0f7c85-finding-your-qualtrics-i-ds",
      ],
      [
          "/docs/Instructions/finding-your-qualtrics-ids.md",
          "/4637b3c0f7c85-finding-your-qualtrics-i-ds",
      ],
      [
          "/finding-your-qualtrics-ids.md",
          "/4637b3c0f7c85-finding-your-qualtrics-i-ds",
      ],
      [
          "/docs/Instructions/Reference/dates-and-times.md",
          "/7367ea545f562-dates-and-times",
      ],
      [
          "/docs/Instructions/dates-and-times.md",
          "/7367ea545f562-dates-and-times",
      ],
      [
          "/dates-and-times.md",
          "/7367ea545f562-dates-and-times",
      ],
      [
          "/docs/Instructions/Reference/language-codes.md",
          "/76d1dbf1c7f54-language-codes",
      ],
      [
          "/docs/Instructions/language-codes.md",
          "/76d1dbf1c7f54-language-codes",
      ],
      [
          "/language-codes.md",
          "/76d1dbf1c7f54-language-codes",
      ],
      ["/docs/Instructions/Reference/parameters.md", "/74fdff2f219cb-parameters"],
      ["/docs/Instructions/parameters.md", "/74fdff2f219cb-parameters"],
      ["/parameters.md", "/74fdff2f219cb-parameters"],
      ["/docs/Instructions/Reference/responses.md", "/3ab4b937676d5-responses"],
      ["/docs/Instructions/responses.md", "/3ab4b937676d5-responses"],
      ["/docs/Instructions/Reference/limits.md", "/a5e9a1a304902-limits"],

      ["/docs/Instructions/limits.md", "/a5e9a1a304902-limits"],
      ["/limits.md", "/a5e9a1a304902-limits"],
      [
          "/docs/Instructions/Security/oauth-authentication-authorization-code.md",
          "/6c02f17c3109f-o-auth-authentication-auth-code",
      ],
      [
          "/docs/Instructions/oauth-authentication-authorization-code.md",
          "/6c02f17c3109f-o-auth-authentication-auth-code",
      ],
      [
        "/oauth-authentication-authorization-code.md",
        "/6c02f17c3109f-o-auth-authentication-auth-code",
      ],
      [
          "/docs/Instructions/Security/oauth-authentication.md",
          "/9398592961ced-o-auth-authentication-client-credentials",
      ],
      [
          "/docs/Instructions/oauth-authentication.md",
          "/9398592961ced-o-auth-authentication-client-credentials",
      ],
      [
        "/oauth-authentication.md",
        "/9398592961ced-o-auth-authentication-client-credentials",
      ],
      [
          "/docs/Instructions/Security/oauthscopes.md",
          "/1450e85735dbf-o-auth-2-0-scopes",
      ],
      [
          "/docs/Instructions/oauthscopes.md",
          "/1450e85735dbf-o-auth-2-0-scopes",
      ],
      [
        "/oauthscopes.md",
        "/1450e85735dbf-o-auth-2-0-scopes",
      ],
      [
          "/docs/Instructions/Security/api-key-authentication.md",
          "/2b4ffbd8af74e-api-key-authentication",
      ],
      [
        "api-key-authentication.md",
        "/2b4ffbd8af74e-api-key-authentication",
      ],
      [
          "/docs/Instructions/api-key-authentication.md",
          "/2b4ffbd8af74e-api-key-authentication",
      ],
      [
          "/docs/Instructions/Security/mutual-tls.md",
          "/o34xl1sk72uc5-mutual-transport-layer-security-m-tls",
      ],
      [
          "/docs/Instructions/mutual-tls.md",
          "/o34xl1sk72uc5-mutual-transport-layer-security-m-tls",
      ],
      [
          "/mutual-tls.md",
          "/o34xl1sk72uc5-mutual-transport-layer-security-m-tls",
      ],
      [
          "/docs/Instructions/Security/additional-security.md",
          "/08f3724a4ea0b-additional-security",
      ],
      [
          "/docs/Instructions/additional-security.md",
          "/08f3724a4ea0b-additional-security",
      ],
      [
        "/additional-security.md",
        "/08f3724a4ea0b-additional-security",
      ],
      ["/docs/Instructions/other-apis.md", "/be8786a7378fb-other-ap-is"],
      [
          "/docs/SDKs/MobileSDK/Mobile Android Intercept SDK/app-feedback-android-api-reference.md",
          "/ZG9jOjgwNTgzNjA-app-feedback-android-api-reference",
      ],
      [
          "/docs/SDKs/MobileSDK/Mobile Android Intercept SDK/getting-started-with-mobile-app-sdk-on-android.md",
          "/ZG9jOjgwNTgzNjE-getting-started-with-mobile-app-sdk-on-android",
      ],
      [
          "/docs/SDKs/MobileSDK/Mobile Android Intercept SDK/android-mobile-app-sdk-releases.md",
          "/ZG9jOjgwNTgzNTk-android-mobile-app-sdk-releases",
      ],
      [
          "/docs/SDKs/MobileSDK/Mobile iOS Intercept SDK/app-feedback-ios-api-reference.md",
          "/ZG9jOjgwNTgzNjU-app-feedback-i-os-api-reference",
      ],
      [
          "/docs/SDKs/MobileSDK/Mobile iOS Intercept SDK/getting-started-with-the-mobile-app-sdk-on-ios.md",
          "/ZG9jOjgwNTgzNjY-getting-started-with-the-mobile-app-sdk-on-i-os",
      ],
      [
          "/docs/SDKs/MobileSDK/Mobile iOS Intercept SDK/ios-mobile-app-sdk-releases.md",
          "/ZG9jOjgwNTgzNjc-i-os-mobile-app-sdk-releases",
      ],
      [
          "/docs/SDKs/MobileSDK/Mobile React Native SDK/app-feedback-react-native-api-reference.md",
          "/ZG9jOjgwNTgzNjI-app-feedback-react-native-api-reference",
      ],
      [
          "/docs/SDKs/MobileSDK/Mobile React Native SDK/getting-started-with-react-native-sdk.md",
          "/ZG9jOjgwNTgzNjM-getting-started-with-react-native-sdk",
      ],
      [
          "/docs/SDKs/MobileSDK/Mobile React Native SDK/react-native-app-sdk-releases.md",
          "/ZG9jOjgwNTgzNjQ-react-native-app-sdk-releases",
      ],
      [
          "/docs/SDKs/MobileSDK/MobileSDKLicense.md",
          "/ZG9jOjg3NzgwNjU-mobile-sdk-license",
      ],
      [
          "/docs/SDKs/MobileSDK/troubleshooting.md",
          "/ZG9jOjgwNTgzNTI-troubleshooting",
      ],
      [
          "/docs/SDKs/MobileSDK/user-analytics-with-the-mobile-app-sdk.md",
          "/ZG9jOjgwNTgzNTM-user-analytics-with-the-mobile-app-sdk",
      ],
      [
          "/docs/SDKs/MobileSDK/v1 Mobile SDK Docs/Mobile v1 Android Intercept SDK/app-feedback-android-api-reference.md",
          "/ZG9jOjE0MjE2ODg4-app-feedback-android-api-reference-v1",
      ],
      [
          "/docs/SDKs/MobileSDK/v1 Mobile SDK Docs/Mobile v1 Android Intercept SDK/getting-started-with-mobile-app-sdk-on-android.md",
          "/ZG9jOjE0MjE2ODg5-getting-started-with-mobile-app-sdk-on-android-v1",
      ],
      [
          "/docs/SDKs/MobileSDK/v1 Mobile SDK Docs/Mobile v1 iOS Intercept SDK/app-feedback-ios-api-reference.md",
          "/ZG9jOjE0MjE2ODky-app-feedback-i-os-api-reference-v1",
      ],
      [
          "/docs/SDKs/MobileSDK/v1 Mobile SDK Docs/Mobile v1 iOS Intercept SDK/getting-started-with-the-mobile-app-sdk-on-ios.md",
          "/ZG9jOjE0MjE2ODkz-getting-started-with-the-mobile-app-sdk-on-i-os-v1",
      ],
      [
          "/docs/SDKs/MobileSDK/v1 Mobile SDK Docs/Mobile v1 React Native SDK/app-feedback-react-native-api-reference.md",
          "/ZG9jOjE0MjE2ODkw-app-feedback-react-native-api-reference-v1",
      ],
      [
          "/docs/SDKs/MobileSDK/v1 Mobile SDK Docs/Mobile v1 React Native SDK/getting-started-with-react-native-sdk.md",
          "/ZG9jOjE0MjE2ODkx-getting-started-with-react-native-sdk-v1",
      ],
      [
          "/docs/SDKs/overview.md",
          "/ZG9jOjg3NjY0NQ-qualtrics-software-development-kits",
      ],
      [
          "/docs/SDKs/Web Intercept SDK/web-feedback-javascript-api-reference-1.md",
          "/ZG9jOjgyMTA5NTM-website-feedback-java-script-api",
      ],
      [
          "/docs/SDKs/XM Native Extensions/Getting Started.md",
          "/ZG9jOjg3NzcxMg-xm-native-extensions-sdk",
      ],
      [
          "/reference/audits.json/paths/~1logs~1activitytypes/get",
          "/b3A6NjA5NDI-list-event-types",
      ],
      ["/reference/audits.json/paths/~1logs/get", "/b3A6NjA5NDM-list-events"],
      [
          "/reference/contactFrequency.json/paths/~1directories~1{directoryId}~1frequencyrules~1{ruleId}/get",
          "/b3A6NjA5NDk-get-contact-frequency-rule",
      ],
      [
          "/reference/contactFrequency.json/paths/~1directories~1{directoryId}~1frequencyrules~1{ruleId}/delete",
          "/b3A6NjA5NTE-delete-contact-frequency-rule",
      ],
      [
          "/reference/contactFrequency.json/paths/~1directories~1{directoryId}~1frequencyrules/get",
          "/b3A6NjA5NDc-list-contact-frequency-rules",
      ],
      [
          "/reference/contactFrequency.json/paths/~1directories~1{directoryId}~1frequencyrules/post",
          "/b3A6NjA5NDg-create-contact-frequency-rule",
      ],
      [
          "/reference/contactFrequency.json/paths/~1directories~1{directoryId}~1frequencyrules~1{ruleId}/put",
          "/b3A6NjA5NTA-update-contact-frequency-rule",
      ],
      [
          "/reference/contactImports.json/paths/~1directories~1{directoryId}~1mailinglists~1{mailingListId}~1transactioncontacts/post",
          "/b3A6NjA5NTI-create-transaction-contacts-import",
      ],
      [
          "/reference/contactImports.json/paths/~1directories~1{directoryId}~1mailinglists~1{mailingListId}~1transactioncontacts~1{importId}/get",
          "/b3A6NjA5NTM-get-transaction-contacts-import-status",
      ],
      [
          "/reference/contactImports.json/paths/~1directories~1{directoryId}~1mailinglists~1{mailingListId}~1transactioncontacts~1{importId}~1summary/get",
          "/b3A6NjA5NTQ-get-transaction-contacts-import-summary",
      ],
      [
          "/reference/contactImports.json/paths/~1directories~1{directoryId}~1imports~1api-import~1jobs~1{importId}~1report/get",
          "/b3A6Mzc4NjA2MA-get-import-report-for-a-completed-import",
      ],
      [
          "/reference/contactTransactions.json/paths/~1directories~1{directoryId}~1contacts~1{contactId}~1transactions/get",
          "/b3A6NjA5NTU-list-contact-transactions",
      ],
      [
          "/reference/contactTransactions.json/paths/~1directories~1{directoryId}~1transactions/post",
          "/b3A6NjA5NTY-create-contact-transaction",
      ],
      [
          "/reference/contactTransactions.json/paths/~1directories~1{directoryId}~1transactions~1{transactionId}/get",
          "/b3A6NjA5NTc-get-contact-transaction",
      ],
      [
          "/reference/contactTransactions.json/paths/~1directories~1{directoryId}~1transactions~1{transactionId}/post",
          "/b3A6NjA5NTg-append-contact-transaction",
      ],
      [
          "/reference/contactTransactions.json/paths/~1directories~1{directoryId}~1transactions~1{transactionId}/put",
          "/b3A6NjA5NTk-update-contact-transaction",
      ],
      [
          "/reference/contactTransactions.json/paths/~1directories~1{directoryId}~1transactions~1{transactionId}/delete",
          "/b3A6NjA5NjA-delete-contact-transaction",
      ],
      [
          "/reference/contacts.json/paths/~1directories~1{directoryId}~1contacts/get",
          "/b3A6NjA5NjE-list-directory-contacts",
      ],
      [
          "/reference/contacts.json/paths/~1directories~1{directoryId}~1contacts/post",
          "/b3A6NjA5NjI-create-directory-contact",
      ],
      [
          "/reference/contacts.json/paths/~1directories~1{directoryId}~1contacts~1{contactId}/put",
          "/b3A6NjA5NjQ-update-directory-contact",
      ],
      [
          "/reference/contacts.json/paths/~1directories~1{directoryId}~1contacts~1{contactId}/delete",
          "/b3A6NjA5NjU-delete-directory-contact",
      ],
      [
          "/reference/contacts.json/paths/~1directories~1{directoryId}~1contacts~1{contactId}/get",
          "/b3A6NjA5NjM-get-directory-contact",
      ],
      [
          "/reference/contacts.json/paths/~1directories~1{directoryId}~1contacts~1search/post",
          "/b3A6NjA5NjY-search-directory-contacts",
      ],
      [
          "/reference/contacts.json/paths/~1directories~1{directoryId}~1contacts~1optedOutContacts/get",
          "/b3A6ODc3NzQ1-list-opted-out-directory-contacts",
      ],
      [
          "/reference/contacts.json/paths/~1directories~1{directoryId}~1contacts~1{contactId}~1history/get",
          "/b3A6NjA5Njc-get-directory-contact-history",
      ],
      [
          "/reference/ccpa.json/paths/~1customer-data-requests~1{customerDataRequestId}/get",
          "/b3A6NjA5NDQ-retrieve-progress-for-a-data-access-job",
      ],
      [
          "/reference/ccpa.json/paths/~1customer-data-requests~1{customerDataRequestId}~1files~1{fileId}/get",
          "/b3A6NjA5NDU-retrieve-customer-data-via-a-file",
      ],
      [
          "/reference/ccpa.json/paths/~1customer-data-requests/post",
          "/b3A6NjA5NDY-create-request-to-start-a-job-to-retrieve-customer-data",
      ],
      [
          "/reference/datasets.json/paths/~1datasets~1{datasetId}/get",
          "/b3A6ODc3NzQ4-get-dataset",
      ],
      [
          "/reference/datasets.json/paths/~1datasets/post",
          "/b3A6ODc3NzQ3-create-dataset",
      ],
      [
          "/reference/datasets.json/paths/~1datasets~1{datasetId}~1data~1{id}/get",
          "/b3A6ODc3NzUw-get-data-record",
      ],
      [
          "/reference/datasets.json/paths/~1datasets~1{datasetId}~1data/post",
          "/b3A6ODc3NzQ5-add-data-to-dataset",
      ],
      [
          "/reference/datasets.json/paths/~1datasets~1{datasetId}~1data~1{id}/put",
          "/b3A6ODc3NzUy-update-data-in-dataset",
      ],
      [
          "/reference/datasets.json/paths/~1datasets~1{datasetId}~1data~1{id}/delete",
          "/b3A6ODc3NzUx-delete-data-from-dataset",
      ],
      [
          "/reference/directories.json/paths/~1directories/get",
          "/b3A6NjA5Njg-list-directories-for-a-brand",
      ],
      [
          "/reference/distributions.json/paths/~1distributions/get",
          "/b3A6NjA5Njk-list-distributions",
      ],
      [
          "/reference/distributions.json/paths/~1distributions/post",
          "/b3A6NjA5NzA-create-distribution",
      ],
      [
          "/reference/distributions.json/paths/~1distributions~1{distributionId}/get",
          "/b3A6NjA5NzE-get-distribution",
      ],
      [
          "/reference/distributions.json/paths/~1distributions~1{distributionId}/delete",
          "/b3A6NjA5NzI-delete-distribution",
      ],
      [
          "/reference/distributions.json/paths/~1distributions~1{distributionId}~1reminders/post",
          "/b3A6NjA5NzM-create-reminder-distribution",
      ],
      [
          "/reference/distributions.json/paths/~1distributions~1{distributionId}~1thankyous/post",
          "/b3A6NjA5NzQ-create-thank-you-distribution",
      ],
      [
          "/reference/distributions.json/paths/~1distributions~1{distributionId}~1links/get",
          "/b3A6NjA5Nzk-list-distribution-links",
      ],
      [
          "/reference/distributions.json/paths/~1distributions~1{distributionId}~1history/get",
          "/b3A6NjA5ODA-list-distribution-history",
      ],
      [
          "/reference/divisions.json/paths/~1divisions~1{divisionId}/put",
          "/b3A6NjA5ODM-update-division",
      ],
      [
          "/reference/divisions.json/paths/~1divisions/post",
          "/b3A6NjA5ODE-create-division",
      ],
      [
          "/reference/divisions.json/paths/~1divisions~1{divisionId}/get",
          "/b3A6NjA5ODI-get-division",
      ],
      [
          "/reference/eventSubscriptions.json/paths/~1eventsubscriptions~1{subscriptionId}/get",
          "/b3A6NjA5ODY-get-subscription",
      ],
      [
          "/reference/eventSubscriptions.json/paths/~1eventsubscriptions/get",
          "/b3A6NjA5ODQ-list-subscriptions",
      ],
      [
          "/reference/eventSubscriptions.json/paths/~1eventsubscriptions/post",
          "/b3A6NjA5ODU-create-subscription",
      ],
      [
          "/reference/participants.json/paths/~1successfactors~1projects~1{projectId}~1evaluators/post",
          "/b3A6MjIxNDk4NA-import-evaluator",
      ],
      [
          "/reference/participants.json/paths/~1employee-directories~1{directoryId}~1import-participants/post",
          "/b3A6MjIxNDk5MQ-import-participants",
      ],
      [
          "/reference/participants.json/paths/~1employee-directories~1{directoryId}~1participants/post",
          "/b3A6NzQ5OTcxMQ-get-all-participants-post",
      ],
      [
          "/reference/participants.json/paths/~1employee-directories~1{directoryId}~1delete-participants/post",
          "/b3A6NDEzMDI2Mg-delete-participants",
      ],
      [
          "/reference/participants.json/paths/~1employee-directories~1{directoryId}~1participants~1{participantId}/get",
          "/b3A6MjIxNDk4OQ-get-global-participant",
      ],
      [
          "/reference/participants.json/paths/~1employee-directories~1{directoryId}~1participants/get",
          "/b3A6MjIxNDk5MA-get-all-participants-deprecated",
      ],
      [
          "/reference/participants.json/paths/~1employee-directories~1{directoryId}~1import-participants~1{jobId}/get",
          "/b3A6MjIxNDk5Mg-get-import-status-for-directory-import",
      ],
      [
          "/reference/participants.json/paths/~1employee-directories~1{directoryId}~1import-participants~1results~1{resultId}/get",
          "/b3A6NDEzMDI2MQ-get-results-for-directory-import-participants-job",
      ],
      [
          "/reference/participants.json/paths/~1employee-directories~1{directoryId}~1delete-participants~1results~1{resultId}/get",
          "/b3A6NDEzMDI2NA-get-results-for-directory-delete-participants-job",
      ],
      [
          "/reference/participants.json/paths/~1employee-directories~1{directoryId}~1export-participants/get",
          "/b3A6NzY2OTYyNw-export-participants",
      ],
      [
          "/reference/participants.json/paths/~1employee-directories~1{directoryId}~1delete-participants~1{jobId}/get",
          "/b3A6NDEzMDI2Mw-get-delete-participants-status",
      ],
      [
          "/reference/participants.json/paths/~1employee-directories~1{directoryId}~1export-participants~1{jobId}/get",
          "/b3A6NzY2OTYyOA-get-export-participants-status",
      ],
      [
          "/reference/participants.json/paths/~1employee-directories~1{directoryId}~1export-participants~1results~1{resultId}~1file/get",
          "/b3A6NzY2OTYyOQ-get-results-for-directory-export-participants-job",
      ],
      [
          "/reference/participants.json/paths/~1ex-projects~1{projectId}~1import-participants/post",
          "/b3A6MjIxNDk4Nw-import-participants",
      ],
      [
          "/reference/participants.json/paths/~1ex-projects~1{projectId}~1copy-participants/post",
          "/b3A6Mzg3Nzc1NQ-copy-participants",
      ],
      [
          "/reference/participants.json/paths/~1ex-projects~1{projectId}~1participants~1{participantId}/get",
          "/b3A6MjIxNDk4NQ-get-specific-participant",
      ],
      [
          "/reference/participants.json/paths/~1ex-projects~1{projectId}~1participants/post",
          "/b3A6NzQ5OTcxMA-get-participants-post",
      ],
      [
          "/reference/participants.json/paths/~1ex-projects~1{projectId}~1participants/get",
          "/b3A6MjIxNDk4Ng-get-participants-deprecated",
      ],
      [
          "/reference/participants.json/paths/~1ex-projects~1{projectId}~1import-participants~1{jobId}/get",
          "/b3A6MjIxNDk4OA-get-import-status",
      ],
      [
          "/reference/participants.json/paths/~1ex-projects~1{projectId}~1import-participants~1results~1{resultId}/get",
          "/b3A6NDEzMDI1MA-get-results-for-project-import-participants-job",
      ],
      [
          "/reference/participants.json/paths/~1ex-projects~1{projectId}~1copy-participants~1{jobId}/get",
          "/b3A6NDEzMDI1Mg-get-copy-participants-status",
      ],
      [
          "/reference/participants.json/paths/~1ex-projects~1{projectId}~1copy-participants~1results~1{resultId}/get",
          "/b3A6NDEzMDI1Mw-get-results-for-project-copy-participants-job",
      ],
      [
          "/reference/participants.json/paths/~1ex-projects~1{projectId}~1delete-participants/post",
          "/b3A6NDEzMDI1NA-delete-participants",
      ],
      [
          "/reference/participants.json/paths/~1ex-projects~1{projectId}~1delete-participants~1{jobId}/get",
          "/b3A6NDEzMDI1NQ-get-delete-participants-status",
      ],
      [
          "/reference/participants.json/paths/~1ex-projects~1{projectId}~1delete-participants~1results~1{resultId}/get",
          "/b3A6NDEzMDI1Ng-get-results-for-project-delete-participants-job",
      ],
      [
          "/reference/participants.json/paths/~1ex-projects~1{projectId}~1export-participants~1results~1{resultId}~1file/get",
          "/b3A6NzY2OTYyNg-get-results-for-ex-project-export-participants-job",
      ],
      [
          "/reference/participants.json/paths/~1ex-projects~1{projectId}~1export-participants/post",
          "/b3A6NzY2OTYyNA-export-participants",
      ],
      [
          "/reference/participants.json/paths/~1ex-projects~1{projectId}~1export-participants~1{jobId}/get",
          "/b3A6NzY2OTYyNQ-get-export-participants-status",
      ],
      [
          "/reference/patch-api.json/paths/~1surveys~1{surveyId}~1responses~1op-aggregate/post",
          "/b3A6OTg5MDI5Nw-create-a-survey-response-op-aggregate",
      ],
      [
          "/reference/patch-api.json/paths/~1surveys~1{surveyId}~1responses~1op-get-with-options/post",
          "/b3A6OTg5MDI5OA-create-a-survey-response-op-get-with-option",
      ],
      [
          "/reference/patch-api.json/paths/~1surveys~1{surveyId}~1responses~1{responseId}~1edits/get",
          "/b3A6OTg5MDMwMA-list-survey-response-edits",
      ],
      [
          "/reference/patch-api.json/paths/~1surveys~1{surveyId}~1responses-edit-progresses~1{progressId}/get",
          "/b3A6OTg5MDMwMQ-get-a-survey-responses-edit-progress",
      ],
      [
          "/reference/patch-api.json/paths/~1surveys~1{surveyId}~1responses/patch",
          "/b3A6OTg5MDI5OQ-modify-a-survey-response",
      ],
      [
          "/reference/groups.json/paths/~1groups~1{groupId}/get",
          "/b3A6NjA5ODk-get-group",
      ],
      [
          "/reference/groups.json/paths/~1groups~1{groupId}/put",
          "/b3A6NjA5OTA-update-group",
      ],
      [
          "/reference/groups.json/paths/~1groups~1{groupId}/delete",
          "/b3A6NjA5OTE-delete-group",
      ],
      ["/reference/groups.json/paths/~1groups/get", "/b3A6NjA5ODc-list-groups"],
      ["/reference/groups.json/paths/~1groups/post", "/b3A6NjA5ODg-create-group"],
      [
          "/reference/groups.json/paths/~1groups~1{groupId}~1members/get",
          "/b3A6MTI4MTA3NA-list-users-in-group",
      ],
      [
          "/reference/groups.json/paths/~1groups~1{groupId}~1members/post",
          "/b3A6NjA5OTI-add-user-to-group",
      ],
      [
          "/reference/groups.json/paths/~1groups~1{groupId}~1members~1{userId}/delete",
          "/b3A6NjA5OTM-remove-user-from-group",
      ],
      [
          "/reference/legacyResponseImportsExports.json/paths/~1responseexports/post",
          "/b3A6NjA5OTQ-legacy-create-response-export",
      ],
      [
          "/reference/legacyResponseImportsExports.json/paths/~1responseexports~1{responseExportId}/get",
          "/b3A6NjA5OTU-legacy-get-response-export-progress",
      ],
      [
          "/reference/legacyResponseImportsExports.json/paths/~1responseexports~1{responseExportId}~1file/get",
          "/b3A6NjA5OTY-legacy-get-response-export-file",
      ],
      [
          "/reference/legacyResponseImportsExports.json/paths/~1responseimports/post",
          "/b3A6NjA5OTc-legacy-import-responses",
      ],
      [
          "/reference/legacyResponseImportsExports.json/paths/~1responseimports~1{responseImportId}/get",
          "/b3A6NjA5OTg-get-legacy-response-import-progress",
      ],
      [
          "/reference/libraries.json/paths/~1libraries~1{libraryId}~1survey~1blocks/get",
          "/b3A6NjEwMDA-list-library-blocks",
      ],
      [
          "/reference/libraries.json/paths/~1libraries/get",
          "/b3A6NjA5OTk-list-libraries",
      ],
      [
          "/reference/libraries.json/paths/~1libraries~1{libraryId}~1survey~1questions/get",
          "/b3A6NjEwMDE-list-library-questions",
      ],
      [
          "/reference/libraries.json/paths/~1libraries~1{libraryId}~1survey~1surveys/get",
          "/b3A6NjEwMDI-list-library-surveys",
      ],
      [
          "/reference/libraries.json/paths/~1libraries~1{libraryId}~1messages/get",
          "/b3A6NjEwMDM-list-library-messages",
      ],
      [
          "/reference/libraries.json/paths/~1libraries~1{libraryId}~1messages/post",
          "/b3A6NjEwMDQ-create-library-message",
      ],
      [
          "/reference/libraries.json/paths/~1libraries~1{libraryId}~1messages~1{messageId}/get",
          "/b3A6NjEwMDU-get-library-message",
      ],
      [
          "/reference/libraries.json/paths/~1libraries~1{libraryId}~1messages~1{messageId}/put",
          "/b3A6NjEwMDY-update-library-message",
      ],
      [
          "/reference/libraries.json/paths/~1libraries~1{libraryId}~1messages~1{messageId}/delete",
          "/b3A6NjEwMDc-delete-library-message",
      ],
      [
          "/reference/libraries.json/paths/~1libraries~1{libraryId}~1graphics/post",
          "/b3A6NjEwMDg-upload-graphic",
      ],
      [
          "/reference/libraries.json/paths/~1libraries~1{libraryId}~1graphics~1{graphicId}/delete",
          "/b3A6NjEwMDk-delete-graphic",
      ],
      [
          "/reference/mailingListContacts.json/paths/~1directories~1{directoryId}~1mailinglists~1{mailingListId}~1contacts/get",
          "/b3A6NjEwMTA-list-contacts-in-mailing-list",
      ],
      [
          "/reference/mailingListContacts.json/paths/~1directories~1{directoryId}~1mailinglists~1{mailingListId}~1bouncedContacts/get",
          "/b3A6NjEwMTI-list-bounced-contacts-in-mailing-list",
      ],
      [
          "/reference/mailingListContacts.json/paths/~1directories~1{directoryId}~1mailinglists~1{mailingListId}~1optedOutContacts/get",
          "/b3A6NjEwMTM-list-opted-out-contacts-in-mailing-list",
      ],
      [
          "/reference/mailingListContacts.json/paths/~1directories~1{directoryId}~1mailinglists~1{mailingListId}~1contacts~1{contactId}/get",
          "/b3A6NjEwMTQ-get-contact-in-mailing-list",
      ],
      [
          "/reference/mailingListContacts.json/paths/~1directories~1{directoryId}~1mailinglists~1{mailingListId}~1contacts~1{contactId}/delete",
          "/b3A6NjEwMTY-delete-contact-in-mailing-list",
      ],
      [
          "/reference/mailingListContacts.json/paths/~1directories~1{directoryId}~1mailinglists~1{mailingListId}~1contacts~1{contactId}/put",
          "/b3A6NjEwMTU-update-contact-in-mailing-list",
      ],
      [
          "/reference/mailingListContacts.json/paths/~1directories~1{directoryId}~1mailinglists~1{mailingListId}~1contacts/post",
          "/b3A6NjEwMTE-create-contact-in-mailing-list",
      ],
      [
          "/reference/mailingListContacts.json/paths/~1directories~1{directoryId}~1mailinglists~1{mailingListId}~1contacts~1{contactId}~1history/get",
          "/b3A6NjEwMTc-get-contact-history-in-mailing-list",
      ],
      [
          "/reference/mailingLists.json/paths/~1directories~1{directoryId}~1mailinglists/get",
          "/b3A6NjEwMTg-list-mailing-lists",
      ],
      [
          "/reference/mailingLists.json/paths/~1directories~1{directoryId}~1mailinglists~1{mailingListId}/get",
          "/b3A6NjEwMjA-get-mailing-list",
      ],
      [
          "/reference/mailingLists.json/paths/~1directories~1{directoryId}~1mailinglists/post",
          "/b3A6NjEwMTk-create-mailing-list",
      ],
      [
          "/reference/mailingLists.json/paths/~1directories~1{directoryId}~1mailinglists~1{mailingListId}/put",
          "/b3A6NjEwMjE-update-mailing-list",
      ],
      [
          "/reference/mailingLists.json/paths/~1directories~1{directoryId}~1mailinglists~1{mailingListId}/delete",
          "/b3A6NjEwMjI-delete-mailing-list",
      ],
      [
          "/reference/mtls.json/paths/~1organizations~1{organizationId}~1clientcertificates/get",
          "/b3A6ODc3ODE0-list-client-certificates",
      ],
      [
          "/reference/mtls.json/paths/~1organizations~1{organizationId}~1clientcertificates/post",
          "/b3A6ODc3ODE1-create-client-certificate",
      ],
      [
          "/reference/mtls.json/paths/~1organizations~1{organizationId}~1clientcertificates~1{certificateId}/get",
          "/b3A6ODc3ODE3-get-client-certificate",
      ],
      [
          "/reference/mtls.json/paths/~1organizations~1{organizationId}~1clientcertificates~1{certificateId}/delete",
          "/b3A6ODc3ODE2-delete-client-certificate",
      ],
      [
          "/reference/organizations.json/paths/~1organizations~1{organizationId}/get",
          "/b3A6NjEwMjM-get-information-about-an-organization",
      ],
      [
          "/reference/surveyDefinitions.json/paths/~1survey-definitions~1{surveyId}/get",
          "/b3A6NjEwNzc-get-survey",
      ],
      [
          "/reference/surveyDefinitions.json/paths/~1survey-definitions~1{surveyId}/delete",
          "/b3A6NjEwNzg-delete-survey",
      ],
      [
          "/reference/surveyDefinitions.json/paths/~1survey-definitions~1{surveyId}~1metadata/get",
          "/b3A6NjEwNzk-get-survey-metadata",
      ],
      [
          "/reference/surveyDefinitions.json/paths/~1survey-definitions/post",
          "/b3A6NjEwNzY-create-survey",
      ],
      [
          "/reference/surveyDefinitions.json/paths/~1survey-definitions~1{surveyId}~1blocks/post",
          "/b3A6NjEwODA-create-block",
      ],
      [
          "/reference/surveyDefinitions.json/paths/~1survey-definitions~1{surveyId}~1blocks~1{blockId}/get",
          "/b3A6NjEwODE-get-block",
      ],
      [
          "/reference/surveyDefinitions.json/paths/~1survey-definitions~1{surveyId}~1blocks~1{blockId}/put",
          "/b3A6NjEwODI-update-block",
      ],
      [
          "/reference/surveyDefinitions.json/paths/~1survey-definitions~1{surveyId}~1blocks~1{blockId}/delete",
          "/b3A6NjEwODM-delete-block",
      ],
      [
          "/reference/surveyDefinitions.json/paths/~1survey-definitions~1{surveyId}~1questions~1{questionId}/get",
          "/b3A6NjEwODY-get-question",
      ],
      [
          "/reference/surveyDefinitions.json/paths/~1survey-definitions~1{surveyId}~1questions/post",
          "/b3A6NjEwODU-create-question",
      ],
      [
          "/reference/surveyDefinitions.json/paths/~1survey-definitions~1{surveyId}~1questions/get",
          "/b3A6NjEwODQ-get-questions",
      ],
      [
          "/reference/surveyDefinitions.json/paths/~1survey-definitions~1{surveyId}~1questions~1{questionId}/put",
          "/b3A6NjEwODc-update-question",
      ],
      [
          "/reference/surveyDefinitions.json/paths/~1survey-definitions~1{surveyId}~1questions~1{questionId}/delete",
          "/b3A6NjEwODg-delete-question",
      ],
      [
          "/reference/surveyDefinitions.json/paths/~1survey-definitions~1{surveyId}~1flow/get",
          "/b3A6NjEwODk-get-flow",
      ],
      [
          "/reference/surveyDefinitions.json/paths/~1survey-definitions~1{surveyId}~1flow/put",
          "/b3A6NjEwOTA-update-flow",
      ],
      [
          "/reference/surveyDefinitions.json/paths/~1survey-definitions~1{surveyId}~1flow~1{flowId}/put",
          "/b3A6NjEwOTE-updates-flow-element-definition",
      ],
      [
          "/reference/surveyDefinitions.json/paths/~1survey-definitions~1{surveyId}~1options/get",
          "/b3A6NjEwOTI-get-options",
      ],
      [
          "/reference/surveyDefinitions.json/paths/~1survey-definitions~1{surveyId}~1options/put",
          "/b3A6NjEwOTM-update-options",
      ],
      [
          "/reference/surveyDefinitions.json/paths/~1survey-definitions~1{surveyId}~1versions/get",
          "/b3A6NjEwOTQ-list-survey-versions",
      ],
      [
          "/reference/surveyDefinitions.json/paths/~1survey-definitions~1{surveyId}~1versions/post",
          "/b3A6NjEwOTU-create-survey-version",
      ],
      [
          "/reference/surveyDefinitions.json/paths/~1survey-definitions~1{surveyId}~1versions~1{versionId}/get",
          "/b3A6NjEwOTY-get-survey-version",
      ],
      [
          "/reference/surveyDefinitions.json/paths/~1survey-definitions~1{surveyId}~1quotagroups~1{quotaGroupId}/put",
          "/b3A6NjExMDU-update-quota-group",
      ],
      [
          "/reference/surveyDefinitions.json/paths/~1survey-definitions~1{surveyId}~1quotagroups~1{quotaGroupId}/delete",
          "/b3A6NjExMDY-delete-quota-group",
      ],
      [
          "/reference/surveyDefinitions.json/paths/~1survey-definitions~1{surveyId}~1quotas/get",
          "/b3A6NjEwOTc-get-quotas-for-survey",
      ],
      [
          "/reference/surveyDefinitions.json/paths/~1survey-definitions~1{surveyId}~1quotas/post",
          "/b3A6NjEwOTg-create-quota",
      ],
      [
          "/reference/surveyDefinitions.json/paths/~1survey-definitions~1{surveyId}~1quotas~1{quotaId}/get",
          "/b3A6NjEwOTk-get-quota-for-survey",
      ],
      [
          "/reference/surveyDefinitions.json/paths/~1survey-definitions~1{surveyId}~1quotas~1{quotaId}/delete",
          "/b3A6NjExMDE-delete-quota",
      ],
      [
          "/reference/surveyDefinitions.json/paths/~1survey-definitions~1{surveyId}~1quotagroups/get",
          "/b3A6NjExMDI-get-quota-groups-for-survey",
      ],
      [
          "/reference/surveyDefinitions.json/paths/~1survey-definitions~1{surveyId}~1quotagroups/post",
          "/b3A6NjExMDM-create-quota-group",
      ],
      [
          "/reference/surveyDefinitions.json/paths/~1survey-definitions~1{surveyId}~1quotagroups~1{quotaGroupId}/get",
          "/b3A6NjExMDQ-get-quota-group-for-survey",
      ],
      [
          "/reference/surveyDefinitions.json/paths/~1survey-definitions~1{surveyId}~1quotas~1{quotaId}/put",
          "/b3A6NjExMDA-update-quota-in-the-given-survey",
      ],
      [
          "/reference/surveyDefinitions.json/paths/~1surveys~1{surveyId}~1languages/get",
          "/b3A6NjExMDc-get-languages",
      ],
      [
          "/reference/surveyDefinitions.json/paths/~1surveys~1{surveyId}~1languages/put",
          "/b3A6NjExMDg-update-languages",
      ],
      [
          "/reference/surveyDefinitions.json/paths/~1surveys~1{surveyId}~1translations~1{languageCode}/get",
          "/b3A6NjExMDk-get-survey-translations-json",
      ],
      [
          "/reference/surveyDefinitions.json/paths/~1surveys~1{surveyId}~1translations~1{languageCode}/put",
          "/b3A6NjExMTA-update-survey-translations",
      ],
      [
          "/reference/researchCore.json/paths/~1mailinglists~1{mailingListId}/delete",
          "/b3A6NjEwMjY-delete-mailing-list",
      ],
      [
          "/reference/researchCore.json/paths/~1mailinglists/get",
          "/b3A6NjEwMjc-list-mailing-lists",
      ],
      [
          "/reference/researchCore.json/paths/~1mailinglists~1{mailingListId}/get",
          "/b3A6NjEwMjQ-get-mailing-list",
      ],
      [
          "/reference/researchCore.json/paths/~1mailinglists~1{mailingListId}/put",
          "/b3A6NjEwMjU-update-mailing-list",
      ],
      [
          "/reference/researchCore.json/paths/~1mailinglists/post",
          "/b3A6NjEwMjg-create-mailing-list",
      ],
      [
          "/reference/researchCore.json/paths/~1mailinglists~1{mailingListId}~1contacts~1{contactId}/get",
          "/b3A6NjEwMzE-get-contact",
      ],
      [
          "/reference/researchCore.json/paths/~1mailinglists~1{mailingListId}~1contacts~1{contactId}/put",
          "/b3A6NjEwMzI-update-contact",
      ],
      [
          "/reference/researchCore.json/paths/~1mailinglists~1{mailingListId}~1contacts/get",
          "/b3A6NjEwMjk-list-contacts",
      ],
      [
          "/reference/researchCore.json/paths/~1mailinglists~1{mailingListId}~1contacts~1{contactId}/delete",
          "/b3A6NjEwMzM-delete-contact",
      ],
      [
          "/reference/researchCore.json/paths/~1mailinglists~1{mailingListId}~1contacts/post",
          "/b3A6NjEwMzA-create-contact",
      ],
      [
          "/reference/researchCore.json/paths/~1mailinglists~1{mailingListId}~1contactimports~1{contactImportId}/get",
          "/b3A6NjEwMzU-get-contacts-import-progress",
      ],
      [
          "/reference/researchCore.json/paths/~1mailinglists~1{mailingListId}~1contactimports~1{contactImportId}~1summary/get",
          "/b3A6NjEwMzY-get-contacts-import-progress-summary",
      ],
      [
          "/reference/researchCore.json/paths/~1mailinglists~1{mailingListId}~1contactimports/post",
          "/b3A6NjEwMzQ-create-contacts-import",
      ],
      [
          "/reference/researchCore.json/paths/~1mailinglists~1{mailingListId}~1samples/get",
          "/b3A6NjEwMzc-list-samples",
      ],
      [
          "/reference/researchCore.json/paths/~1mailinglists~1{mailingListId}~1samples~1{sampleId}/get",
          "/b3A6NjEwMzg-get-sample",
      ],
      [
          "/reference/rightToErasure.json/paths/~1op-erase-personal-data/get",
          "/b3A6NjEwNDg-list-requests",
      ],
      [
          "/reference/rightToErasure.json/paths/~1op-erase-personal-data~1{requestId}/get",
          "/b3A6NjEwNTA-get-request",
      ],
      [
          "/reference/rightToErasure.json/paths/~1op-erase-personal-data/post",
          "/b3A6NjEwNDk-create-request",
      ],
      [
          "/reference/sampleDefinitions.json/paths/~1directories~1{directoryId}~1samples~1definitions/get",
          "/b3A6NjEwNTE-list-sample-definitions",
      ],
      [
          "/reference/sampleDefinitions.json/paths/~1directories~1{directoryId}~1samples~1definitions/post",
          "/b3A6NjEwNTI-create-sample-definition",
      ],
      [
          "/reference/sampleDefinitions.json/paths/~1directories~1{directoryId}~1samples~1definitions~1{sampleDefinitionId}/get",
          "/b3A6NjEwNTM-get-sample-definition",
      ],
      [
          "/reference/sampleDefinitions.json/paths/~1directories~1{directoryId}~1samples~1definitions~1{sampleDefinitionId}/delete",
          "/b3A6NjEwNTU-delete-sample-definition",
      ],
      [
          "/reference/sampleDefinitions.json/paths/~1directories~1{directoryId}~1samples~1definitions~1{sampleDefinitionId}/put",
          "/b3A6NjEwNTQ-update-sample-definition",
      ],
      [
          "/reference/samples.json/paths/~1directories~1{directoryId}~1samples~1{sampleId}/get",
          "/b3A6NjEwNTg-get-sample",
      ],
      [
          "/reference/samples.json/paths/~1directories~1{directoryId}~1samples~1{sampleId}/put",
          "/b3A6NjEwNTk-update-sample",
      ],
      [
          "/reference/samples.json/paths/~1directories~1{directoryId}~1samples~1{sampleId}/delete",
          "/b3A6NjEwNjA-delete-sample",
      ],
      [
          "/reference/samples.json/paths/~1directories~1{directoryId}~1samples/get",
          "/b3A6NjEwNTY-list-samples",
      ],
      [
          "/reference/samples.json/paths/~1directories~1{directoryId}~1samples/post",
          "/b3A6NjEwNTc-create-sample",
      ],
      [
          "/reference/samples.json/paths/~1directories~1{directoryId}~1samples~1{sampleId}~1contacts/get",
          "/b3A6NjEwNjE-get-sample-contacts",
      ],
      [
          "/reference/samples.json/paths/~1directories~1{directoryId}~1samples~1progress~1{progressId}/get",
          "/b3A6NjEwNjI-get-create-sample-progress",
      ],
      [
          "/reference/segmentContacts.json/paths/~1directories~1{directoryId}~1segments~1{segmentId}~1contacts/get",
          "/b3A6MjIxNjQzNg-list-contacts-in-segment",
      ],
      [
          "/reference/segmentContacts.json/paths/~1directories~1{directoryId}~1segments~1{segmentId}~1contacts~1{contactId}/get",
          "/b3A6MjIxNjQzNw-get-contact-in-segment",
      ],
      [
          "/reference/segments.json/paths/~1directories~1{directoryId}~1segments/get",
          "/b3A6MjIxNjQzOQ-list-segments",
      ],
      [
          "/reference/segments.json/paths/~1directories~1{directoryId}~1segments/post",
          "/b3A6MjIxNjQ0MA-create-segment",
      ],
      [
          "/reference/segments.json/paths/~1directories~1{directoryId}~1segments~1{segmentId}/delete",
          "/b3A6MjIxNjQ0Mw-delete-segment-definition",
      ],
      [
          "/reference/segments.json/paths/~1directories~1{directoryId}~1segments~1{segmentId}~1refresh-jobs/post",
          "/b3A6MjIxNjQ0NA-create-segment-refresh-job",
      ],
      [
          "/reference/segments.json/paths/~1directories~1{directoryId}~1segments~1{segmentId}~1refresh-jobs~1{progressId}/get",
          "/b3A6MjIxNjQ0NQ-get-segment-refresh-job-status",
      ],
      [
          "/reference/segments.json/paths/~1directories~1{directoryId}~1segments~1{segmentId}/get",
          "/b3A6MjIxNjQ0MQ-get-segment-definition",
      ],
      [
          "/reference/segments.json/paths/~1directories~1{directoryId}~1segments~1{segmentId}/put",
          "/b3A6MjIxNjQ0Mg-update-segment-definition",
      ],
      [
          "/reference/sms.json/paths/~1distributions~1sms~1{smsDistributionId}/get",
          "/b3A6NjEwNzQ-get-sms-distribution",
      ],
      [
          "/reference/sms.json/paths/~1distributions~1sms/get",
          "/b3A6NjEwNzM-list-sms-distribution",
      ],
      [
          "/reference/sms.json/paths/~1distributions~1sms/post",
          "/b3A6NjEwNzI-create-new-survey-distribution-via-sms",
      ],
      [
          "/reference/sms.json/paths/~1distributions~1sms~1{smsDistributionId}/delete",
          "/b3A6NjEwNzU-delete-sms-distribution",
      ],
      [
          "/reference/singleResponses.json/paths/~1responses~1{responseId}/put",
          "/b3A6NjEwNzA-update-response",
      ],
      [
          "/reference/singleResponses.json/paths/~1surveys~1{surveyId}~1responses~1{responseId}~1uploaded-files~1{fileId}/get",
          "/b3A6MzE2Mjc3MQ-retrieve-a-user-uploaded-file",
      ],
      [
          "/reference/singleResponses.json/paths/~1surveys~1{surveyId}~1responses/post",
          "/b3A6NjEwNjc-create-a-new-response",
      ],
      [
          "/reference/singleResponses.json/paths/~1surveys~1{surveyId}~1responses~1{responseId}/delete",
          "/b3A6NjEwNjk-delete-a-survey-response",
      ],
      [
          "/reference/singleResponses.json/paths/~1surveys~1{surveyId}~1response-schema/get",
          "/b3A6NjEwNzE-retrieve-survey-json-schema",
      ],
      [
          "/reference/singleResponses.json/paths/~1surveys~1{surveyId}~1responses~1{responseId}/get",
          "/b3A6NjEwNjg-retrieve-a-survey-response",
      ],
      [
          "/reference/sessions.json/paths/~1surveys~1{surveyId}~1sessions/post",
          "/b3A6NjEwNjM-start-a-new-session",
      ],
      [
          "/reference/sessions.json/paths/~1surveys~1{surveyId}~1sessions~1{sessionId}/get",
          "/b3A6NjEwNjQ-fetch-current-page-of-existing-session",
      ],
      [
          "/reference/sessions.json/paths/~1surveys~1{surveyId}~1sessions~1{sessionId}/post",
          "/b3A6NjEwNjU-update-a-session",
      ],
      [
          "/reference/sessions.json/paths/~1surveys~1{surveyId}~1sessions~1{sessionId}/delete",
          "/b3A6NjEwNjY-delete-session-without-saving-response",
      ],
      [
          "/reference/surveys.json/paths/~1surveys~1{surveyId}~1permissions~1collaborations/post",
          "/b3A6NjExMTY-share-survey",
      ],
      [
          "/reference/surveys.json/paths/~1surveys~1{surveyId}/get",
          "/b3A6NjExMTE-get-survey",
      ],
      [
          "/reference/surveys.json/paths/~1surveys~1{surveyId}/put",
          "/b3A6NjExMTI-update-survey",
      ],
      [
          "/reference/surveys.json/paths/~1surveys~1{surveyId}/delete",
          "/b3A6NjExMTM-delete-survey",
      ],
      [
          "/reference/surveys.json/paths/~1surveys/get",
          "/b3A6NjExMTQ-list-surveys",
      ],
      [
          "/reference/surveys.json/paths/~1surveys/post",
          "/b3A6NjExMTU-import-survey",
      ],
      [
          "/reference/surveys.json/paths/~1surveys~1{surveyId}~1embeddeddatafields/post",
          "/b3A6NjExMTc-insert-embedded-data-fields",
      ],
      [
          "/reference/surveys.json/paths/~1surveys~1{surveyId}~1quotas/get",
          "/b3A6NjExMTg-get-survey-quotas",
      ],
      [
          "/reference/responseImportsExports.json/paths/~1surveys~1{surveyId}~1export-responses~1{exportProgressId}/get",
          "/b3A6NjEwNDE-get-response-export-progress",
      ],
      [
          "/reference/responseImportsExports.json/paths/~1surveys~1{surveyId}~1filters/get",
          "/b3A6NjEwNDU-get-list-of-available-filters",
      ],
      [
          "/reference/responseImportsExports.json/paths/~1surveys~1{surveyId}~1export-responses~1{fileId}~1file/get",
          "/b3A6NjEwNDM-get-response-export-file",
      ],
      [
          "/reference/responseImportsExports.json/paths/~1surveys~1{surveyId}~1export-responses/post",
          "/b3A6NjEwMzk-start-response-export",
      ],
      [
          "/reference/responseImportsExports.json/paths/~1surveys~1{surveyId}~1v2-api-export-responses~1{fileId}~1file/get",
          "/b3A6NjEwNDQ-get-v2-api-export-file",
      ],
      [
          "/reference/responseImportsExports.json/paths/~1surveys~1{surveyId}~1v2-api-export-responses/post",
          "/b3A6NjEwNDA-start-a-v2-api-format-response-export",
      ],
      [
          "/reference/responseImportsExports.json/paths/~1surveys~1{surveyId}~1v2-api-export-responses~1{exportProgressId}/get",
          "/b3A6NjEwNDI-get-v2-api-format-response-export-progress",
      ],
      [
          "/reference/responseImportsExports.json/paths/~1surveys~1{surveyId}~1import-responses/post",
          "/b3A6NjEwNDY-start-response-import",
      ],
      [
          "/reference/responseImportsExports.json/paths/~1surveys~1{surveyId}~1import-responses~1{importProgressId}/get",
          "/b3A6NjEwNDc-get-import-progress",
      ],
      [
          "/reference/tickets.json/paths/~1tickets~1{ticketKey}~1events/get",
          "/b3A6NjExMjU-retrieve-events-for-a-ticket",
      ],
      [
          "/reference/tickets.json/paths/~1tickets/get",
          "/b3A6NjExMTk-retrieve-tickets-for-account",
      ],
      [
          "/reference/tickets.json/paths/~1tickets~1search/post",
          "/b3A6NjExMjg-search-tickets",
      ],
      [
          "/reference/tickets.json/paths/~1tickets~1statuses/get",
          "/b3A6NjExMjA-get-statuses",
      ],
      [
          "/reference/tickets.json/paths/~1tickets~1statuses/put",
          "/b3A6NjExMjE-set-statuses",
      ],
      [
          "/reference/tickets.json/paths/~1tickets~1{ticketKey}/get",
          "/b3A6NjExMjI-retrieve-ticket",
      ],
      [
          "/reference/tickets.json/paths/~1tickets~1{ticketKey}/put",
          "/b3A6NjExMjM-update-ticket",
      ],
      [
          "/reference/tickets.json/paths/~1tickets~1{ticketKey}/delete",
          "/b3A6NjExMjQ-delete-a-ticket",
      ],
      [
          "/reference/tickets.json/paths/~1tickets~1{ticketKey}~1rootCauses/get",
          "/b3A6NjExMjY-retrieve-root-causes-for-a-ticket",
      ],
      [
          "/reference/tickets.json/paths/~1tickets~1{ticketKey}~1ticketAdditionalData/put",
          "/b3A6NjExMjc-update-ticket-s-additional-data",
      ],
      [
          "/reference/transactionBatches.json/paths/~1directories~1{directoryId}~1transactionbatches/get",
          "/b3A6NjExMzI-list-transaction-batches",
      ],
      [
          "/reference/transactionBatches.json/paths/~1directories~1{directoryId}~1transactionbatches/post",
          "/b3A6NjExMzM-create-transaction-batch",
      ],
      [
          "/reference/transactionBatches.json/paths/~1directories~1{directoryId}~1transactionbatches~1{batchId}~1transactions~1{transactionId}/delete",
          "/b3A6NjExMzY-remove-transaction-from-batch",
      ],
      [
          "/reference/transactionBatches.json/paths/~1directories~1{directoryId}~1transactionbatches~1{batchId}/delete",
          "/b3A6NjExMzg-delete-transaction-batch",
      ],
      [
          "/reference/transactionBatches.json/paths/~1directories~1{directoryId}~1transactionbatches~1{batchId}~1transactions/get",
          "/b3A6NjExMzQ-list-transactions-in-a-batch",
      ],
      [
          "/reference/transactionBatches.json/paths/~1directories~1{directoryId}~1transactionbatches~1{batchId}~1transactions/post",
          "/b3A6NjExMzU-add-transactions-to-batch",
      ],
      [
          "/reference/transactionBatches.json/paths/~1directories~1{directoryId}~1transactionbatches~1{batchId}/get",
          "/b3A6NjExMzc-get-transaction-batch",
      ],
      ["/reference/users.json/paths/~1whoami/get", "/b3A6NjExNDY-who-am-i"],
      ["/reference/users.json/paths/~1users/get", "/b3A6NjExMzk-list-users"],
      ["/reference/users.json/paths/~1users/post", "/b3A6NjExNDA-create-user"],
      [
          "/reference/users.json/paths/~1users~1{userId}/get",
          "/b3A6NjExNDE-get-user",
      ],
      [
          "/reference/users.json/paths/~1users~1{userId}/delete",
          "/b3A6NjExNDI-delete-user",
      ],
      [
          "/reference/users.json/paths/~1users~1{userId}/put",
          "/b3A6NjExNDM-update-user",
      ],
      [
          "/reference/users.json/paths/~1users~1{userId}~1apitoken/get",
          "/b3A6NjExNDQ-get-user-api-token",
      ],
      [
          "/reference/users.json/paths/~1users~1{userId}~1apitoken/post",
          "/b3A6NjExNDU-create-user-api-token",
      ],
      [
          "/reference/whatsApp.json/paths/~1distributions~1whatsapp/post",
          "/b3A6NjExNDc-create-new-whats-app-survey-distribution",
      ],
      [
          "/reference/whatsApp.json/paths/~1distributions~1whatsapp/get",
          "/b3A6NjExNDg-list-whats-app-distribution",
      ],
      [
          "/reference/whatsApp.json/paths/~1distributions~1whatsapp~1{whatsAppDistributionId}/get",
          "/b3A6NjExNDk-get-whats-app-distribution",
      ],
      [
          "/reference/whatsApp.json/paths/~1distributions~1whatsapp~1{whatsAppDistributionId}/delete",
          "/b3A6NjExNTA-delete-whats-app-distribution",
      ],
  ]);

  let decodedPath = decodeURI(path);
  let redirectPath = "";
  // check the map above for the V1 link
  if (redirectMap.has(decodedPath)) {
      redirectPath = redirectMap.get(decodedPath);
      // save the # anchor if present
      if (anchor !== "") {
          redirectPath += anchor;
      }
  }
  // send them to the homepage if they made it here and the page isn't in the map above
  else {
      redirectPath = "/";
  }

  return redirectPath;
}
